<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5">  Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LA GESTIÓN Y SEGUIMIENTO DE CASOS ANTISOBORNO:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-indent fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaGestionesSeguimiento.length}}</span>
                            <br>
                            <span class="text-muted">Gestión y seguimiento de casos antisoborno registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión y seguimiento de casos antisoborno</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('017-37001-GSA','c') == 1" cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalNuevaGestionSeguimiento = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nueva gestión y seguimiento</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5">Gestión y seguimiento de casos antisoborno registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaGestionesSeguimiento" :fields="cabecera" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(internoExterno)="param">
                                    <span v-if="param.item.internoExterno==1">Interno</span>
                                    <span v-else-if="param.item.internoExterno==2">Externo</span>
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('017-37001-GSA','u') == 1" @click="abrirModalActualizarGestionSeguimiento(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('017-37001-GSA','d') == 1" @click="eliminarGestionSeguimiento(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalNuevaGestionSeguimiento">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva gestión y segumiento de casos antisoborno</span>
            </h6>
            <CButtonClose @click="modalNuevaGestionSeguimiento = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(nuevaGestionSeguimiento)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="número de denuncia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número de denuncia:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimiento.numeroDenuncia" :state="getValidationState(validationContext)" placeholder="Ingrese el número de denuncia"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="denuncia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Denuncia:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimiento.denuncia" :state="getValidationState(validationContext)" placeholder="Ingrese la denuncia"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="persona expuesta" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Persona expuesta:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimiento.personaExpuesta" :state="getValidationState(validationContext)" placeholder="Ingrese la persona expuesta"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargo de persona expuesta" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargo de persona expuesta:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimiento.cargoPersona" :state="getValidationState(validationContext)" placeholder="Ingrese el cargo de la persona"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="involucrado" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Involucrado:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimiento.involucrado" :state="getValidationState(validationContext)" placeholder="Ingrese el involucrado"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargo involucrado y entidad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargo involucrado y entidad:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimiento.cargoInvolucrado" :state="getValidationState(validationContext)" placeholder="Ingrese el cargo del involucrado"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargar pruebas" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargar pruebas:" class="mb-2">
                                <b-form-file ref="file" class="text-left" v-model="datosGestionSeguimiento.documentoPruebas" v-on:change="handleFileUpload" :state="getValidationState(validationContext)" :placeholder="datosGestionSeguimiento.nombreDocumentoPruebas ? datosGestionSeguimiento.nombreDocumentoPruebas : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosGestionSeguimiento.nombreDocumentoPruebas ? datosGestionSeguimiento.nombreDocumentoPruebas : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="estado de atención" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Estado de atención:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimiento.estadoAtencion" :state="getValidationState(validationContext)" placeholder="Ingrese el cargo del involucrado"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="">
                            <b-form-group label="" class="mb-2">
                                <b-form-radio-group class="mt-2" plain v-model="datosGestionSeguimiento.internoExterno" :options="[{value:1,text:'Interno'},{value:2,text:'Externo'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="descripción del suceso" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Descripción del suceso:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosGestionSeguimiento.descripcionSuceso" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción del suceso"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="descripción de la afectación organizativa" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Descripción de la afectación organizativa:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosGestionSeguimiento.descripcionAfectacion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción de la afectación organizativa"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosGestionSeguimiento.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalNuevaGestionSeguimiento = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalActualizarGestionSeguimiento">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar gestión y segumiento de casos antisoborno</span>
            </h6>
            <CButtonClose @click="modalActualizarGestionSeguimiento = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarGestionSeguimiento)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="número de denuncia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número de denuncia:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimientoActualizar.numeroDenuncia" :state="getValidationState(validationContext)" placeholder="Ingrese el número de denuncia"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="denuncia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Denuncia:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimientoActualizar.denuncia" :state="getValidationState(validationContext)" placeholder="Ingrese la denuncia"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="persona expuesta" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Persona expuesta:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimientoActualizar.personaExpuesta" :state="getValidationState(validationContext)" placeholder="Ingrese la persona expuesta"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargo de persona expuesta" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargo de persona expuesta:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimientoActualizar.cargoPersona" :state="getValidationState(validationContext)" placeholder="Ingrese el cargo de la persona"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="involucrado" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Involucrado:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimientoActualizar.involucrado" :state="getValidationState(validationContext)" placeholder="Ingrese el involucrado"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargo involucrado y entidad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargo involucrado y entidad:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimientoActualizar.cargoInvolucrado" :state="getValidationState(validationContext)" placeholder="Ingrese el cargo del involucrado"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargar pruebas" v-slot="validationContext">
                            <b-form-group label="Cargar pruebas:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosGestionSeguimientoActualizar.urlDocumentoPruebas != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosGestionSeguimientoActualizar.urlDocumentoPruebas)" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file ref="fileActualizar" class="text-left" v-model="datosGestionSeguimientoActualizar.documentoPruebas" v-on:change="handleFileUploadActualizar" :state="getValidationState(validationContext)" :placeholder="datosGestionSeguimientoActualizar.nombreDocumentoPruebas ? datosGestionSeguimientoActualizar.nombreDocumentoPruebas : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosGestionSeguimientoActualizar.nombreDocumentoPruebas ? datosGestionSeguimientoActualizar.nombreDocumentoPruebas : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="estado de atención" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Estado de atención:" class="mb-2">
                                <b-form-input v-model="datosGestionSeguimientoActualizar.estadoAtencion" :state="getValidationState(validationContext)" placeholder="Ingrese el cargo del involucrado"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="">
                            <b-form-group label="" class="mb-2">
                                <b-form-radio-group class="mt-2" plain v-model="datosGestionSeguimientoActualizar.internoExterno" :options="[{value:1,text:'Interno'},{value:2,text:'Externo'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="descripción del suceso" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Descripción del suceso:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosGestionSeguimientoActualizar.descripcionSuceso" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción del suceso"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="descripción de la afectación organizativa" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Descripción de la afectación organizativa:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosGestionSeguimientoActualizar.descripcionAfectacion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción de la afectación organizativa"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosGestionSeguimientoActualizar.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarGestionSeguimiento = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalNuevaGestionSeguimiento: false,
            modalActualizarGestionSeguimiento: false,

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "numeroDenuncia",
                    label: "Número",
                    class: "text-center",
                },
                {
                    key: "denuncia",
                    label: "Denuncia",
                    class: "text-center",
                },
                {
                    key: "personaExpuesta",
                    label: "Persona",
                    class: "text-center",
                },
                {
                    key: "estadoAtencion",
                    label: "Estado de atención",
                    class: "text-center",
                },
                {
                    key: "internoExterno",
                    label: "Interno/Externo",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaGestionesSeguimiento: [],
            datosGestionSeguimiento: {
                idCliente: '',
                numeroDenuncia: '',
                denuncia: '',
                personaExpuesta: '',
                cargoPersona: '',
                involucrado: '',
                cargoInvolucrado: '',
                documentoPruebas: null,
                estadoAtencion: '',
                internoExterno: 1,
                descripcionSuceso: '',
                descripcionAfectacion: '',
                descripcion: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            },
            datosGestionSeguimientoActualizar: {
                idGestionSeguimiento: '',
                idCliente: '',
                numeroDenuncia: '',
                denuncia: '',
                personaExpuesta: '',
                cargoPersona: '',
                involucrado: '',
                cargoInvolucrado: '',
                documentoPruebas: null,
                urlDocumentoPruebas: '',
                nombreDocumentoPruebas: '',
                estadoAtencion: '',
                internoExterno: 1,
                descripcionSuceso: '',
                descripcionAfectacion: '',
                descripcion: '',
                ejercicio: '',
            },
            disabled: false,
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }

                //this.listaOrganigramas.documento = this.$refs.file.files[0];
            }
        },
        handleFileUploadActualizar(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar"].reset();
                    return;
                }

                //this.listaOrganigramas.documento = this.$refs.file.files[0];
            }
        },
        listarGestionesSeguimiento() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-gestiones-seguimiento-sga", {
                        params: {
                            idCliente: me.datosGestionSeguimiento.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    me.listaGestionesSeguimiento = response.data
                    me.totalRows = me.listaGestionesSeguimiento.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        nuevaGestionSeguimiento() {
            let me = this;
            me.disabled = true;
            const formData = new FormData();
            formData.append("file", me.datosGestionSeguimiento.documentoPruebas);
            formData.append("folder", 'sga/gestion-y-seguimiento-de-casos-antisoborno');
            formData.append("datosGestionSeguimiento", JSON.stringify(me.datosGestionSeguimiento));
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-gestion-seguimiento-sga",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarGestionesSeguimiento();
                    me.modalNuevaGestionSeguimiento = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarGestionSeguimiento() {
            let me = this;
            me.disabled = true;
            const formData = new FormData();
            formData.append("file", me.datosGestionSeguimientoActualizar.documentoPruebas);
            formData.append("folder", 'sga/gestion-y-seguimiento-de-casos-antisoborno');
            formData.append("datosGestionSeguimiento", JSON.stringify(me.datosGestionSeguimientoActualizar));
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-gestion-seguimiento-sga",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarGestionesSeguimiento();
                    me.modalActualizarGestionSeguimiento = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalActualizarGestionSeguimiento(param) {
            let me = this;

            me.datosGestionSeguimientoActualizar.idGestionSeguimiento = param.item.idGestionSeguimiento;
            me.datosGestionSeguimientoActualizar.idCliente = param.item.idCliente;
            me.datosGestionSeguimientoActualizar.numeroDenuncia = param.item.numeroDenuncia;
            me.datosGestionSeguimientoActualizar.denuncia = param.item.denuncia;
            me.datosGestionSeguimientoActualizar.personaExpuesta = param.item.personaExpuesta;
            me.datosGestionSeguimientoActualizar.cargoPersona = param.item.cargoPersona;
            me.datosGestionSeguimientoActualizar.involucrado = param.item.involucrado;
            me.datosGestionSeguimientoActualizar.cargoInvolucrado = param.item.cargoInvolucrado;
            me.datosGestionSeguimientoActualizar.estadoAtencion = param.item.estadoAtencion;
            me.datosGestionSeguimientoActualizar.urlDocumentoPruebas = param.item.urlDocumentoPruebas;
            me.datosGestionSeguimientoActualizar.nombreDocumentoPruebas = param.item.nombreDocumentoPruebas;
            me.datosGestionSeguimientoActualizar.internoExterno = param.item.internoExterno;
            me.datosGestionSeguimientoActualizar.descripcionSuceso = param.item.descripcionSuceso;
            me.datosGestionSeguimientoActualizar.descripcionAfectacion = param.item.descripcionAfectacion;
            me.datosGestionSeguimientoActualizar.descripcion = param.item.descripcion;
            me.datosGestionSeguimientoActualizar.ejercicio = param.item.ejercicio;

            me.modalActualizarGestionSeguimiento = true;
        },
        resetModalNuevaGestionSeguimiento() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosGestionSeguimiento.numeroDenuncia = '';
            this.datosGestionSeguimiento.denuncia = '';
            this.datosGestionSeguimiento.personaExpuesta = '';
            this.datosGestionSeguimiento.cargoPersona = '';
            this.datosGestionSeguimiento.involucrado = '';
            this.datosGestionSeguimiento.cargoInvolucrado = '';
            this.datosGestionSeguimiento.documentoPruebas = null;
            this.datosGestionSeguimiento.internoExterno = 1;
            this.datosGestionSeguimiento.descripcionSuceso = '';
            this.datosGestionSeguimiento.descripcionAfectacion = '';
            this.datosGestionSeguimiento.descripcion = '';
        },
        resetModalActualizarGestionSeguimiento() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosGestionSeguimientoActualizar.documentoPruebas = null;

        },
        eliminarGestionSeguimiento(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la gestión y segumiento?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-gestion-seguimiento-sga", {
                            idGestionSeguimiento: param.item.idGestionSeguimiento,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarGestionesSeguimiento();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalNuevaGestionSeguimiento: function (val) {
            if (val == false) {
                this.resetModalNuevaGestionSeguimiento();
            }
        },
        modalActualizarGestionSeguimiento: function (val) {
            if (val == false) {
                this.resetModalActualizarGestionSeguimiento();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosGestionSeguimiento.idCliente = user.uidClient;
            this.listarGestionesSeguimiento();
        }

    }

}
</script>
